// Frameworks
import React, { useEffect } from "react"
import { navigate } from "gatsby"

// Static Route
const IndexPage = () => {
  useEffect(() => {
    //Show nothing as all pages are client routes
    navigate("/app")
  })

  return null
}

export default IndexPage
